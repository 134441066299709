import React from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { GeoAlt, Layers } from "react-bootstrap-icons";

import DropDownGroup from "./DropDownGroup";
import LayerItem from "./LayerItem";
import MapboxGeocoder from "./Mapbox/MapboxGeocoder";
import SiteItem from "./SiteItem";

const geocoderModules = {
  MapboxGeocoder,
};

const MapControl = (props) => {
  const { layerControls, sites, geocoderConfig } = props;

  const Geocoder = geocoderModules[geocoderConfig.moduleComponentName];

  const siteItems = sites.map((site) => <SiteItem key={site.id} {...site} />);

  const layerItems = layerControls.map((layerControl, index) => {
    return (
      <LayerItem key={`${layerControl.ids[0]}-${index}`} {...layerControl} />
    );
  });

  return (
    <Row className="map-control-container no-gutters">
      <Col xs="auto" className="mr-1">
        <DropDownGroup
          id="map-layer-drop-down"
          label="Map Layers"
          selectMultiple={true}
          icon={<Layers size={18} className="d-inline d-lg-none" />}
        >
          {layerItems}
        </DropDownGroup>
      </Col>

      <Col xs="auto" className="mr-1">
        <DropDownGroup
          id="site-drop-down"
          label="Go to site"
          icon={<GeoAlt size={18} className="d-inline d-lg-none" />}
        >
          {siteItems}
        </DropDownGroup>
      </Col>

      <Col
        xs={{ span: 12, order: "first" }}
        sm={{ span: "auto", order: "last" }}
        className="mr-1 mb-1 mr-sm-0 mb-sm-0"
      >
        <Geocoder {...geocoderConfig} />
      </Col>
    </Row>
  );
};

MapControl.propTypes = {
  layerControls: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  geocoderConfig: PropTypes.object.isRequired,
};

export default MapControl;
