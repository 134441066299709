/**
 * Dumb page component that renders displayables specified in the config.
 *
 * A displayable is either a section, or a module. A section is a collection of modules.
 *
 * Displayables must be imported and added to the `modules` object before they can be rendered.
 *
 * The component is supposed to be rendered in a Bootstrap container.
 */

import React, { useEffect } from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";

import CardWithButton from "components/CardWithButton/CardWithButton";
import EISChaptersGroup from "components/ContentGroup/EISChaptersGroup";
import GalleryGroup from "components/ContentGroup/GalleryGroup";
import DynamicPageList from "components/DynamicPages/DynamicPageList";
import FeedbackCarousel from "components/Feedback/FeedbackCarousel";
import ContactUsForm from "components/Forms/ContactUsForm";
import FrequentlyAskedQuestions from "components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import Gallery from "components/Gallery/Gallery";
import HeroImage from "components/Heroes/HeroImage";
import HeroVideo from "components/Heroes/HeroVideo";
import MapPage from "components/InteractiveMap/MapPage";
import ButtonLink from "components/Links/ButtonLink";
import CardImageLink from "components/Links/CardImageLink";
import ImageLink from "components/Links/ImageLink";
import BaseMarkdownRenderer from "components/Markdown/BaseMarkdownRenderer";
import FirstTimeModal from "components/Modals/FirstTimeModal";
import PDFViewer from "components/PDFViewer/PDFViewer";
import SectionHeading from "components/Sections/SectionHeading";
import SectionHeadingWithButtons from "components/Sections/SectionHeadingWithButtons";
import SectionText from "components/Sections/SectionText";
import VirtualConsultationRoom from "components/VirtualConsultationRoom/VirtualConsultationRoom";
import { setActivePageSection } from "state/actions";

import EditableRow from "./EditableRow";
import Spacer from "./Spacer";

const modules = {
  BaseMarkdownRenderer,
  ButtonLink,
  CardImageLink,
  CardWithButton,
  ContactUsForm,
  DynamicPageList,
  EISChaptersGroup,
  FeedbackCarousel,
  FirstTimeModal,
  FrequentlyAskedQuestions,
  Gallery,
  GalleryGroup,
  HeroImage,
  HeroVideo,
  ImageLink,
  MapPage,
  PDFViewer,
  SectionHeading,
  SectionHeadingWithButtons,
  SectionText,
  Spacer,
  VirtualConsultationRoom,
};

/**
 * Wrap the child modules in a Container and Row when `wrapWithContainer` is true.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ContainerWrapper = (props) => {
  return (
    <Container>
      <Row {...props}>{props.children}</Row>
    </Container>
  );
};

const hydrateModuleFromConfig = (moduleConfig) => {
  const Displayable = modules[moduleConfig.moduleComponentName];
  return (
    <Col key={`${moduleConfig.name}-col`} {...moduleConfig.columnProps}>
      <Displayable key={moduleConfig.name} {...moduleConfig.moduleProps} />
    </Col>
  );
};

const Page = ({ config, isEditable }) => {
  const dispatch = useDispatch();

  const children = config.map((displayableConfig) => {
    let columns;

    if (displayableConfig.children) {
      // This displayable is a section.
      columns = displayableConfig.children.map(hydrateModuleFromConfig);
    } else {
      // This displayable is a module.
      columns = [hydrateModuleFromConfig(displayableConfig)];
    }

    const Wrapper = displayableConfig.wrapWithContainer
      ? ContainerWrapper
      : React.Fragment;
    const children = (
      <Wrapper {...displayableConfig.wrappedRowProps}>{columns}</Wrapper>
    );

    // React does not like changes to the number of hooks that run on a re-render.
    // When adding a row in "edit" mode, the `useInView` hooks would otherwise
    // run on the newly added row, which causes React to fail the render.
    if (isEditable) {
      return (
        <div key={`${displayableConfig.name}-row`}>
          <div
            id={displayableConfig.id}
            className="internal-anchor-target"
            aria-hidden={true}
          />

          <EditableRow
            id={displayableConfig.name}
            {...displayableConfig.rowProps}
          >
            {children}
          </EditableRow>
        </div>
      );
    }

    // Add an intersection observer to the row and set the activeSectionId when
    // fully in the view.
    const { ref, inView } = useInView({ threshold: 0.7 });

    useEffect(() => {
      // Delay dispatching until the `NavBar` has rendered. This avoids errors connecting
      // the `NavBar` component to the Redux store whilst this is still rendering.
      if (inView) {
        dispatch(setActivePageSection(displayableConfig.id));
      }
    }, [inView]);

    return (
      <div key={`${displayableConfig.name}-row`}>
        <div
          id={displayableConfig.id}
          className="internal-anchor-target"
          aria-hidden={true}
        />

        <Row
          ref={ref}
          id={displayableConfig.name}
          {...displayableConfig.rowProps}
        >
          {children}
        </Row>
      </div>
    );
  });

  return <>{children}</>;
};

// Required property shapes for modules and sections.
const modulePropShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  moduleComponentName: PropTypes.string.isRequired,
  columnProps: PropTypes.object,
  moduleProps: PropTypes.object,
  rowProps: PropTypes.object,
  wrapWithContainer: PropTypes.bool,
  wrappedRowProps: PropTypes.object,
});
const sectionPropShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(modulePropShape),
  rowProps: PropTypes.object,
  wrapWithContainer: PropTypes.bool,
  wrappedRowProps: PropTypes.object,
});

Page.propTypes = {
  config: PropTypes.arrayOf(sectionPropShape, modulePropShape).isRequired,
  isEditable: PropTypes.bool,
};

export default Page;
