import { mapConfig } from "src/app-config";

import { getStyleDefinition, getStyleParams } from "./common";

const fetchBasemapStyleLayerIds = async (style) => {
  const { username, styleId } = getStyleParams(style);
  const layers = await getStyleDefinition(username, styleId);
  return layers.map((layer) => layer.id);
};

const getBasemapLayerIds = async (storageKey, basemapId, basemapType) => {
  if (basemapType === "layer") {
    sessionStorage.setItem(storageKey, JSON.stringify([basemapId]));
    return;
  }
  const layerIds = await fetchBasemapStyleLayerIds(basemapId);
  sessionStorage.setItem(storageKey, JSON.stringify(layerIds));
  return;
};

export const storeBasemapIds = async () => {
  const basemaps = mapConfig.mapComponentConfig.basemaps;
  const storeBasemaps = Object.keys(basemaps).map((key) => {
    const { id, type } = basemaps[key];
    const storageKey = `${key}-basemap`;
    return getBasemapLayerIds(storageKey, id, type);
  });
  await Promise.all(storeBasemaps);
  return;
};

export const setAlternateBasemapVisibility = (layers) => {
  const alternateBasemapIds = JSON.parse(
    sessionStorage.getItem("alternate-basemap")
  );
  return layers.map((layer) => {
    if (alternateBasemapIds.includes(layer.id)) {
      return {
        ...layer,
        layout: {
          ...layer.layout,
          visibility: "none",
        },
      };
    }
    return layer;
  });
};
