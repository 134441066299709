import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Gallery from "components/Gallery/Gallery";
import { baseThumbnailProps, lightboxSources } from "components/Images/types";
import SectionHeading from "components/Sections/SectionHeading";

const GalleryGroup = (props) => {
  const groupHeading = (
    <Col>
      <SectionHeading
        id={props.groupHeadingId}
        heading={{
          priority: 3,
          text: props.groupHeadingName,
          style: {
            scrollMarginTop: "70px",
          },
        }}
        separator={{ style: { width: "100%" } }}
      />
    </Col>
  );

  return (
    <>
      <Row>{groupHeading}</Row>
      <Gallery {...props.gallery} />
    </>
  );
};

GalleryGroup.propTypes = {
  groupHeadingName: PropTypes.string.isRequired,
  groupHeadingId: PropTypes.string.isRequired,
  gallery: PropTypes.shape({
    thumbnailProps: PropTypes.arrayOf(PropTypes.shape(baseThumbnailProps))
      .isRequired,
    lightboxSources: lightboxSources.isRequired,
  }),
};

export default GalleryGroup;
