import React from "react";

import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

const SectionHeading = (props) => {
  const HeaderTag = `h${props.heading.priority}`;

  const hasSeparator = !_isEmpty(props.separator);
  const hasSubheading = !_isEmpty(props.subheading);

  return (
    <div
      className={
        props.alignCenter ? "d-flex align-items-center flex-column" : ""
      }
    >
      <HeaderTag id={props.id} style={props.heading.style}>
        {props.heading.text}
      </HeaderTag>
      {hasSeparator ? <hr style={props.separator.style} /> : null}
      {hasSubheading ? (
        <p style={props.subheading.style}>{props.subheading.text}</p>
      ) : null}
    </div>
  );
};

SectionHeading.propTypes = {
  id: PropTypes.string,
  alignCenter: PropTypes.bool,
  heading: PropTypes.shape({
    priority: PropTypes.number.isRequired,
    style: PropTypes.object,
    text: PropTypes.string.isRequired,
  }).isRequired,
  separator: PropTypes.shape({
    style: PropTypes.object,
  }),
  subheading: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
};

export default SectionHeading;
