/**
 * Render all Hotspots for the Virtual Consultation Room.
 *
 * This component will render all hotspot templates defined in
 * `src/templates/hotspots` in hidden divs, so the `VirtualConsultationRoom`
 * component can copy them into the 360 panorama canvas using Marzipano.
 */
import React, { useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

/**
 * A small wrapper component that provides a trigger callback for to let the parent
 * know of changes to the MdxHotspot state.
 *
 * @param node, the graphql node containing the hotspot MDX to render.
 * @returns {JSX.Element}
 * @constructor
 *
 * Changing the elevation of the hotspot content does not make it appear above
 * other hotspots as they are independent. The reliable way of making the active
 * hotspots appear above other hotspots is to set the z-index of this element
 * when active. This is achieved by passing a callback function down through the
 * MDXRenderer to the MdxHotspot component. The `onTrigger` prop will be available
 * in the hotspot templates and must be passed to the `MdxHotspot` component.
 */
const Hotspot = ({ node }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTrigger = (value) => {
    setIsOpen(value);
  };

  return (
    <div
      id={node.childMdx.frontmatter.id}
      className="d-none"
      style={isOpen ? { zIndex: 1 } : { zIndex: 0 }}
    >
      <MDXRenderer name={node.name} onTrigger={(value) => handleTrigger(value)}>
        {node.childMdx.body}
      </MDXRenderer>
    </div>
  );
};

const HotspotRenderer = () => {
  const data = useStaticQuery(graphql`
    query HotspotTemplateData {
      hotspots: allFile(
        filter: {
          extension: { eq: "mdx" }
          sourceInstanceName: { eq: "templates" }
          relativeDirectory: { eq: "hotspots" }
        }
      ) {
        edges {
          node {
            name
            childMdx {
              frontmatter {
                id
              }
              body
            }
          }
        }
      }
    }
  `);

  return data.hotspots.edges.map(({ node }) => {
    return <Hotspot key={node.childMdx.frontmatter.id} node={node} />;
  });
};

export default HotspotRenderer;
