import React from "react";

import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

const FrequentlyAskedQuestions = ({ questions }) => {
  return questions.map((item, index) => {
    // React-bootstrap's accordions default to only enabling one content
    // block open at a time. Multiple accordions are required to keep FAQ
    // questions open until the user explicitly closes them.
    return (
      <Accordion key={index} className={item.className}>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="text-center"
          >
            {item.question}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <Card.Body className="text-center my-3 faq-answer">
              {item.answer}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  });
};

FrequentlyAskedQuestions.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      className: PropTypes.string,
    })
  ),
};

export default FrequentlyAskedQuestions;
