/**
 * A simple thumbnail that triggers the site wide Lightbox using Redux.
 */
import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { PlayFill } from "react-bootstrap-icons";
import Col from "react-bootstrap/Col";

import withLightboxTrigger from "./LightboxTrigger";
import { thumbnailProps } from "./types";

const Thumbnail = withLightboxTrigger((props) => {
  const data = useStaticQuery(graphql`
    query {
      thumbnails: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "thumbnails" }
          sourceInstanceName: { eq: "images" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 450) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const image = data.thumbnails.edges.find(
    ({ node }) => node.name === props.fileName
  );

  if (image === undefined) {
    // Don't render anything if the given fileName doesn't match any files.
    return null;
  }

  // Gatsby-image doesn't provide an `onClick` handler for the `Img` component.
  return (
    <Col {...props.columnProps}>
      <div
        onClick={props.triggerLightbox}
        onKeyDown={(event) =>
          event.key === "Enter" ? props.triggerLightbox() : undefined
        }
        role="button"
        tabIndex={0}
        className={props.isOpaqueUntilHover ? "thumbnail-image-holder" : ""}
      >
        <Img fluid={image.node.childImageSharp.fluid} alt={image.node.name} />

        {props.isForVideo && (
          <PlayFill className="thumbnail-image-icon-overlay" />
        )}

        {props.hoverText && (
          <div className="thumbnail-image-hover-text">{props.hoverText}</div>
        )}
      </div>
    </Col>
  );
});

Thumbnail.propTypes = thumbnailProps;

Thumbnail.defaultProps = {
  columnProps: { xs: 12 },
  isOpaqueUntilHover: true,
  startingLightboxIndex: 0,
};

export default Thumbnail;
