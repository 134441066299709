import React from "react";

import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";

import withMapActions from "./withMapActions";

const SiteItem = (props) => {
  const { label, locationOptions, layerOptions, setLayersAndLocation } = props;

  const handleClick = () => {
    setLayersAndLocation(locationOptions, layerOptions);
  };

  return (
    <Dropdown.Item
      as="button"
      style={{ width: "100%" }}
      className="pl-2 pt-1 pb-1 text-wrap small map-control-dropdown-item"
      onClick={handleClick}
    >
      {label}
    </Dropdown.Item>
  );
};

SiteItem.propTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  locationOptions: PropTypes.object.isRequired,
  setLayersAndLocation: PropTypes.func.isRequired,
  layerOptions: PropTypes.array,
}).isRequired;

export default withMapActions(SiteItem);
