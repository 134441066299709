import React from "react";

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import SectionHeading from "./SectionHeading";

const SectionHeadingWithButtons = (props) => {
  const sectionHeading = (
    <Col>
      <SectionHeading {...props.sectionHeadingProps} />
    </Col>
  );

  const buttons =
    props.buttons &&
    props.buttons.map((buttonProps, buttonIndex) => (
      <Col key={buttonIndex} xs="auto">
        <Button className="mr-2 mt-2" {...buttonProps.props}>
          {buttonProps.text}
        </Button>
      </Col>
    ));

  return (
    <>
      <Row>{sectionHeading}</Row>
      <Row className="justify-content-start" noGutters={true}>
        {buttons}
      </Row>
    </>
  );
};

SectionHeadingWithButtons.propTypes = {
  sectionHeadingProps: PropTypes.object.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      props: PropTypes.object.isRequired,
    })
  ),
};

export default SectionHeadingWithButtons;
