import PropTypes from "prop-types";

import withModalTrigger from "./ModalTrigger";

const FirstTimeModal = withModalTrigger((props) => {
  // If we haven't visited this page before, show the modal.
  if (
    typeof window !== "undefined" &&
    !window.localStorage.getItem(`visited-${props.pageName}`)
  ) {
    window.localStorage.setItem(`visited-${props.pageName}`, true);
    props.setActiveModal();
  }
  return null;
});

FirstTimeModal.propTypes = {
  targetModalId: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
};

export default FirstTimeModal;
