import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import _flatten from "lodash/flatten";
import _isEmpty from "lodash/isEmpty";
import { Dropdown, ListGroup } from "react-bootstrap";
import * as Icons from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { mapConfig } from "src/app-config";

const getVisibleLayerLegendItems = (visibleLayerIds) => {
  const legendsForVisibleLayers = [];
  Object.keys(mapConfig.layerLegends).forEach((key) => {
    if (visibleLayerIds.includes(key)) {
      const legendItems = mapConfig.layerLegends[key].map((legend) => {
        return {
          ...legend,
          id: key,
        };
      });
      legendsForVisibleLayers.push(legendItems);
    }
  });
  return _flatten(legendsForVisibleLayers);
};

/**
 * Build a Dropdown.Item for each legend item specified in the legend config for the layer.
 *
 * @param legendType string, one of `icon`, `img`, `fill` or `line`.
 * @param colour string, the colour for the legend item `line`, `fill` or `icon`. Not used for `img`.
 * @param outline string, the outline colour. `fill` only.
 * @param circle boolean, legend is circle with outline. `fill` only.
 * @param text string, the text used for the legend e.g: "Bus stations".
 * @param value string, the value for the `legendType` as follows:
 *   - When `legendType` is `icon`, `value` must be the Bootstrap Icon name, e.g. `CardList`.
 *   - When `legendType` is `img`, `value` must be the filename of the image, e.g. `my-icon`.
 *       These images must be in `images/icons`.
 *   - When `legendType` is `line` or `fill`, `value` must be the border-style type, e.g. "dotted".
 * @param imageData the results of the graphql query, required for `img` legend items.
 * @param index number, used for the key for the `Dropdown.Item`.
 * @param isSmallScreen boolean, whether the site is being rendered on a small screen or not.
 * @returns {JSX.Element}
 */
const buildLegendItem = (
  { legendType, colour, text, value, outline, circle },
  imageData,
  index,
  isSmallScreen
) => {
  let legendKey;

  if (legendType === "icon") {
    const Icon = Icons[value];
    legendKey = (
      <Icon className="legend-key legend-icon" style={{ color: colour }} />
    );
  } else if (legendType === "img") {
    const image = imageData.icons.edges.find(({ node }) => node.name === value);

    if (image !== undefined) {
      legendKey = (
        <Img
          className="legend-key legend-img"
          fixed={image.node.childImageSharp.fixed}
          alt={value}
        />
      );
    } else {
      legendKey = <></>;
    }
  } else if (legendType === "fill") {
    legendKey = (
      <span
        className="legend-key legend-fill"
        style={{
          background: colour,
          borderStyle: value,
          borderColor: outline,
          borderRadius: circle ? "50%" : "4px",
        }}
      />
    );
  } else {
    legendKey = (
      <span
        className="legend-key legend-line"
        style={{ borderBottomStyle: value, borderBottomColor: colour }}
      />
    );
  }

  const Wrapper = isSmallScreen ? Dropdown.ItemText : ListGroup.Item;

  return (
    <Wrapper
      key={index}
      className={
        "d-flex align-items-center small border-0 px-2 legend-item-wrapper" +
        (isSmallScreen ? "" : " py-2")
      }
    >
      {legendKey} <span className="pl-2 text-left">{text}</span>
    </Wrapper>
  );
};

const buildLegendItems = (activeLegend, imageData, isSmallScreen) => {
  const hasCommonLegend = mapConfig.commonLegend.length > 0;
  const hasActiveLayerLegend = !_isEmpty(activeLegend);

  if (!hasCommonLegend && !hasActiveLayerLegend) {
    const noLegend = {
      legendType: "icon",
      colour: "rgba(0, 0, 0, 0)",
      text: "No layers selected",
      value: "Circle",
    };
    return buildLegendItem(noLegend, imageData, "no-legend", isSmallScreen);
  }

  return (
    <>
      {mapConfig.commonLegend.map((config, index) => {
        return buildLegendItem(config, imageData, index, isSmallScreen);
      })}
      {hasCommonLegend && hasActiveLayerLegend && <hr className="my-2" />}
      {hasActiveLayerLegend &&
        activeLegend.map((config, index) => {
          return buildLegendItem(
            config,
            imageData,
            `${config.id}-${index}`,
            isSmallScreen
          );
        })}
    </>
  );
};

const MapLegend = () => {
  const imageData = useStaticQuery(graphql`
    query {
      icons: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "icons" }
          sourceInstanceName: { eq: "images" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(height: 25, width: 25) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const layers = useSelector((state) => state.interactiveMap.layers);
  const visibleLayers = Object.keys(layers).filter(
    (key) => layers[key].visible
  );
  const activeLegend = getVisibleLayerLegendItems(visibleLayers);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  if (isSmallScreen) {
    return (
      <Dropdown className="legend">
        <Dropdown.Toggle
          id="map-legend"
          className="map-control-dropdown-toggle d-flex justify-content-center align-items-center"
        >
          Legend
        </Dropdown.Toggle>

        <Dropdown.Menu className="legend-menu">
          {buildLegendItems(activeLegend, imageData, isSmallScreen)}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <div className="border bg-white shadow-sm rounded legend legend-menu">
      {buildLegendItems(activeLegend, imageData, isSmallScreen)}
    </div>
  );
};

export default MapLegend;
