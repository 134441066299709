/**
 * A HeroImage is a simple component that displays a hero image. An image file
 * with the name `hero` is expected to be found in the `images` directory.
 *
 * Pass `fullHeight` to make the image take up the full height of the display.
 */

import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

const HeroImage = ({ fullHeight, style }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(
        name: { eq: "hero" }
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      ) {
        id
        name
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Img
      fluid={data.heroImage.childImageSharp.fluid}
      className={fullHeight ? "min-height-full-page" : ""}
      style={style}
      fadeIn={false}
      loading="eager"
    />
  );
};

HeroImage.propTypes = {
  fullHeight: PropTypes.bool,
  style: PropTypes.object,
};

export default HeroImage;
