import React from "react";

import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

import withMapActions from "./withMapActions";

const LayerItem = (props) => {
  const { ids, label, setLayersVisibility } = props;
  const layers = useSelector((state) => state.interactiveMap.layers);

  const checked = ids.every((id) => layers[id].visible);

  const handleClick = () => {
    const visible = !checked;
    setLayersVisibility(ids, visible);
  };

  return (
    <Dropdown.Item
      as="button"
      onClick={handleClick}
      style={{ width: "100%" }}
      className="pl-2 pt-1 pb-1 text-wrap small map-control-dropdown-item"
    >
      <Form.Check type="checkbox" label={label} checked={checked} readOnly />
    </Dropdown.Item>
  );
};

LayerItem.propTypes = PropTypes.shape({
  ids: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  label: PropTypes.string.isRequired,
  setLayersVisibility: PropTypes.func.isRequired,
}).isRequired;

export default withMapActions(LayerItem);
