import React, { useContext, useEffect, useRef } from "react";

import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";
import "mapbox-gl/dist/mapbox-gl.css";

import { MapContext, types } from "../MapContext";
import { initialMapState } from "../types";

import { setInitialVisibleLayers } from "./MapboxActions";
import { setPopups } from "./Popups";
import PopupContainer from "./Popups/PopupContainer";
import { addLayersToMap, mapLoaded } from "./layers";
import { layerDefinition, styledVectorTile, popups, icons } from "./types";

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;

const addMapControls = (map) => {
  map.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
    })
  );
  map.addControl(new mapboxgl.NavigationControl());
  map.addControl(
    new mapboxgl.ScaleControl({
      maxWidth: 100,
      unit: "metric",
    }),
    "bottom-right"
  );
};

const setFallBackIcon = (map, id) => {
  const fallBackIconPath = "/icons/fallback-icon.png";
  map.loadImage(fallBackIconPath, (_error, fallBackIcon) => {
    map.addImage(id, fallBackIcon);
  });
};

const loadIcons = (map, icons) => {
  icons.forEach(({ id, fileName }) => {
    map.loadImage(`/icons/${fileName}`, (error, image) => {
      if (error) {
        setFallBackIcon(map, id);
        return;
      }
      map.addImage(id, image);
    });
  });
};

const initialiseMap = (params) => {
  const {
    initialMapState,
    customAttribution,
    layers,
    mapboxStyle,
    mapContainer,
    popupRef,
    popups,
    setContext,
    setLoading,
    icons,
  } = params;
  const { locationOptions, layerOptions } = initialMapState;

  const map = new mapboxgl.Map({
    ...locationOptions,
    container: mapContainer.current,
    style: mapboxStyle,
    ...(customAttribution && { customAttribution: customAttribution }),
  });

  addMapControls(map);

  map.on("load", () => {
    loadIcons(map, icons);
    setPopups(map, popups, popupRef);
    setContext({ type: types.SET_MAP_LOADED });
    addLayersToMap(map, layers)
      .then(() => {
        setInitialVisibleLayers(map, layerOptions.visible);
        return mapLoaded(map);
      })
      .then(() => {
        setLoading(false);
      });
  });

  setContext({ type: types.SET_MAP, payload: map });
};

const Mapbox = (props) => {
  const popupOptions = {
    closeButton: false,
    closeOnClick: false,
    closeOnMove: false,
    className: "mapbox-popup-element",
  };
  const mapRef = useRef(null);
  const popupRef = useRef(new mapboxgl.Popup(popupOptions));
  const [mapState, setMapState] = useContext(MapContext);

  const mapConstructors = {
    mapContainer: mapRef,
    popupRef: popupRef,
    setContext: setMapState,
    ...props,
  };

  useEffect(() => {
    if (!mapState.loaded) {
      props.setLoading(true);
      initialiseMap(mapConstructors);
    }
  }, [mapState.loaded]);

  return (
    <div ref={mapRef} className="interactive-map-container">
      <PopupContainer popups={props.popups} popupRef={popupRef} />
    </div>
  );
};

Mapbox.propTypes = {
  initialMapState: initialMapState,
  customAttribution: PropTypes.string.isRequired,
  layers: PropTypes.arrayOf(
    PropTypes.oneOfType([layerDefinition, styledVectorTile])
  ).isRequired,
  mapboxStyle: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  popups: popups,
  icons: icons,
};

export default Mapbox;
